<script>
import { mapActions, mapGetters } from 'vuex';
import helper from '@/plugins/helper';

export default {
    name: 'SalesManager',

    data: () => ({
        first_name: '',
        last_name: '',
        url_referral: '',
        loading: false,
        loadingUser: false,
        loadingGenerate: false,
        loading_id: '',
        menu: false,
        invest_url: process.env.VUE_APP_INVEST_URL,
        open_dialog: false,

        filter: {
            date: [],
            user_name: '',
            code: '',
            count_user: '',
        },
    }),

    methods: {
        ...mapActions('salesManager', [
            'fetchSalesManagers',
            'fetchManagerUsers',
            'addSalesManager',
        ]),

        numberWithSpaces(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },

        async getManagerUsers(id) {
            this.loading_id = id;
            this.open_dialog = true;
            this.loadingUser = true;
            await this.fetchManagerUsers(id);
            this.loadingUser = false;
            this.loading_id = '';
        },

        async submitCreateLink(reset) {
            this.loadingGenerate = true;
            
            const validate = await this.$refs.createManagerRef.validate();
            if (validate) {
                await this.addSalesManager({
                    first_name: this.first_name,
                    last_name: this.last_name,
                })
                    .then(code => {
                        this.url_referral =
                            process.env.VUE_APP_INVEST_URL +
                            `signup?referral_code=${code}`;

                        this.$alertify.success(
                            'Success creating an affiliate link',
                        );

                        this.fetchSalesManagers();
                    })
                    .catch(({ response: { data } }) => {
                        this.$alertify.error(data.message);
                    });
            }

            this.loadingGenerate = false;
            this.first_name = '';
            this.last_name = '';
            if (reset) {
                reset();
            }
        },

        copyUrl() {
            navigator.clipboard.writeText(this.url_referral);
            this.$alertify.success(`URL copied successfully`);
        },

        copyText(value) {
            navigator.clipboard.writeText(value);
            this.$alertify.success(`Copied successfully`);
        },

        resetDate() {
            this.filter.date = [];
            this.menu = false;
        },

        closeDialog() {
            this.open_dialog = false;
        },
    },

    computed: {
        ...mapGetters('salesManager', {
            managers: 'getSalesManagers',
            manager_users: 'getManagerUsers',
        }),

        totalShares() {
            return this.manager_users.reduce(
                (acc, curr) => acc + curr.quantity_lots,
                0,
            );
        },

        totalPrice() {
            return this.manager_users.reduce(
                (acc, curr) => acc + curr.amount,
                0,
            );
        },

        headers() {
            return [
                {
                    text: 'Sales Manager',
                    value: 'user_name',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.user_name.toLowerCase());
                    },
                },
                {
                    text: 'Date of create',
                    value: 'created_at_human',
                    filter: (value, search, item) => {
                        const THREE_HOURS_IN_SECONDS = 3 * 3600;
                        const DAY_IN_SECONDS = 86399;

                        if (this.filter.date.length === 2) {
                            let startDateTimestamp = helper.toTimestamp(
                                this.filter.date['0'],
                            );
                            let endDateTimestamp = helper.toTimestamp(
                                this.filter.date['1'],
                            );

                            if (startDateTimestamp > endDateTimestamp) {
                                [startDateTimestamp, endDateTimestamp] = [
                                    endDateTimestamp,
                                    startDateTimestamp,
                                ];
                            }
                            return (
                                startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                                    item.created_at &&
                                endDateTimestamp +
                                    DAY_IN_SECONDS -
                                    THREE_HOURS_IN_SECONDS >=
                                    item.created_at
                            );
                        }

                        if (this.filter.date.length === 1) {
                            let startDateTimestamp = helper.toTimestamp(
                                this.filter.date['0'],
                            );
                            let endDateTimestamp =
                                startDateTimestamp + DAY_IN_SECONDS;

                            return (
                                startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                                    item.created_at &&
                                endDateTimestamp - THREE_HOURS_IN_SECONDS >=
                                    item.created_at
                            );
                        }

                        return true;
                    },
                    align: 'center',
                },
                {
                    text: 'Affiliate link',
                    value: 'affiliate_link',
                    align: 'center',
                },

                {
                    text: 'Referral code',
                    value: 'code',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.code.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Registered Users',
                    value: 'count_user',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.count_user.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Details',
                    value: 'details',
                    align: 'center',
                },
            ];
        },

        headersUser() {
            return [
                {
                    text: 'Email Users',
                    value: 'mail',
                },

                {
                    text: 'Purchased Shares',
                    value: 'quantity_lots',
                },

                {
                    text: 'Total Investment (€)',
                    value: 'amount',
                },
            ];
        },
    },

    async created() {
        this.loading = true;
        await this.fetchSalesManagers();
        this.loading = false;
    },
};
</script>

<template>
    <div>
        <v-row justify="center">
            <v-col cols="8" sm="5">
                <v-card style="font-size: 16px;">
                    <v-toolbar flat dark color="primary">
                        <v-toolbar-title>
                            Creating an affiliate link
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <validation-observer
                        ref="createManagerRef"
                        v-slot="{ invalid, reset }"
                    >
                        <form
                            @submit.prevent="submitCreateLink(reset)"
                            style="padding:0 30px"
                        >
                            <validation-provider
                                v-slot="{ errors }"
                                name="First Name"
                                rules="required|max:20|min:3|alpha_spaces"
                            >
                                <v-text-field
                                    v-model="first_name"
                                    :error-messages="errors"
                                    label="Enter your First Name"
                                    required
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Last Name"
                                rules="required|max:20|min:3|alpha_spaces"
                            >
                                <v-text-field
                                    v-model="last_name"
                                    :error-messages="errors"
                                    label="Enter your Last Name"
                                    required
                                ></v-text-field>
                            </validation-provider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    type="submit"
                                    color="primary"
                                    :disabled="invalid"
                                    :loading="loadingGenerate"
                                >
                                    Generate
                                </v-btn>
                            </v-card-actions>
                        </form>
                    </validation-observer>
                    <template v-if="url_referral">
                        <v-divider></v-divider>
                        <v-card-text>
                            {{ url_referral }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="copyUrl">
                                Copy
                            </v-btn>
                        </v-card-actions>
                    </template>
                </v-card>
            </v-col>
        </v-row>
        <v-divider style="margin-top: 20px"></v-divider>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            dense
            :headers="headers"
            :items="managers"
            :items-per-page="50"
            :footer-props="{
                'items-per-page-options': [10, 20, 40, 60, 80, 100],
            }"
        >
            <template v-slot:[`item.affiliate_link`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            @click="
                                copyText(
                                    invest_url +
                                        `signup?referral_code=${item.code}`,
                                )
                            "
                            v-on="on"
                            v-bind="attrs"
                            style="cursor: pointer"
                        >
                            {{
                                invest_url + `signup?referral_code=${item.code}`
                            }}
                        </span>
                    </template>
                    <span>Click to copy</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            @click="copyText(item.code)"
                            v-on="on"
                            v-bind="attrs"
                            style="cursor: pointer"
                        >
                            {{ item.code }}
                        </span>
                    </template>
                    <span>Click to copy</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.details`]="{ item }">
                <v-tooltip bottom v-if="item.count_user > 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on="on"
                            v-bind="attrs"
                            icon
                            text
                            :loading="item.id === loading_id"
                            @click.prevent="getManagerUsers(item.id)"
                        >
                            <v-icon size="30">mdi-information-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Details</span>
                </v-tooltip>
            </template>

            <template v-slot:[`body.prepend`]>
                <tr>
                    <td>
                        <v-text-field v-model="filter.user_name"></v-text-field>
                    </td>

                    <td>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter.date"
                                    label="Date range"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.date" no-title range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="resetDate">
                                    Clear
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </td>

                    <td></td>

                    <td>
                        <v-text-field v-model="filter.code"></v-text-field>
                    </td>

                    <td>
                        <v-text-field
                            v-model="filter.count_user"
                        ></v-text-field>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="open_dialog" persistent max-width="600">
            <v-card style="font-size: 16px;">
                <v-toolbar flat dark color="primary">
                    <v-toolbar-title>Manager Users</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <div class="text-subtitle-1">
                        Total Shares: {{ totalShares }}
                    </div>
                    <div class="text-subtitle-1">
                        Total Amount: € {{ totalPrice }}
                    </div>
                </v-card-text>

                <v-data-table
                    :loading="loadingUser"
                    loading-text="Loading... Please wait"
                    dense
                    :items-per-page="10"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30],
                    }"
                    :headers="headersUser"
                    :items="loadingUser ? [] : manager_users"
                >
                    <template v-slot:[`item.quantity_lots`]="{ item }">
                        {{
                            item.quantity_lots
                                ? numberWithSpaces(item.quantity_lots)
                                : '-'
                        }}
                    </template>

                    <template v-slot:[`item.amount`]="{ item }">
                        {{ item.amount ? numberWithSpaces(item.amount) : '-' }}
                    </template>
                </v-data-table>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDialog()">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
